<template>
    <!-- Part of the tabler icon set: https://github.com/tabler/tabler-icons -->
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
    >
        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
        <rect x="3" y="15" width="6" height="6" rx="2" />
        <rect x="15" y="15" width="6" height="6" rx="2" />
        <rect x="9" y="3" width="6" height="6" rx="2" />
        <path d="M6 15v-1a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v1" />
        <line x1="12" y1="9" x2="12" y2="12" />
    </svg>
</template>

<script>
    export default {
        name: "IconSitemap",
    }
</script>
