<template>
    <!-- Part of the tabler icon set: https://github.com/tabler/tabler-icons -->
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke-width="2"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
    >
        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
        <line x1="12" y1="8" x2="12.01" y2="8" />
        <rect x="4" y="4" width="16" height="16" rx="2" />
        <polyline points="11 12 12 12 12 16 13 16" />
    </svg>
</template>

<script>
    export default {
        name: "IconInfoSquare",
    }
</script>
