export default {
    setConfigValues(state, value){
        state.configValues = value
    },
    setContentPage(state, value){
        state.contentPage = value
    },
    setPosts(state, value){
        state.posts = value
    },
    setUrlPage(state, value){
        state.urlPage = value
    },
}
