export default {
  configValues:{
    logo: '',
    copyRight: '',
    colorText: '#000000',
    bodyColor: '#FFFFFF',
    primaryColor: '',
    secondaryColor: '',
  },
  contentPage:{},
  urlPage:{},
  posts:[]
}
